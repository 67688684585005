const bootstrap = require('bootstrap');

require('./style');

document.addEventListener('DOMContentLoaded', function() {
  const options = {};
  var toastElList = [].slice.call(document.querySelectorAll('.toast'))
  var toastList = toastElList.map((toastEl) => {
    return new bootstrap.Toast(toastEl, options)
  });

  toastList.forEach((bsToast) => {
    bsToast.show();
  });
});
