import Typewriter from 'typewriter-effect/dist/core';

var buildTypewriter = (e) => {
  var clips = $(e).data('clips').split(';');
  clips.unshift($( e ).text());

  new Typewriter(e, {
    strings: clips,
    autoStart: true,
    loop: true
  });
};

$( document ).ready(() => {
  $( '.cc-typewriter' ).each((i, e) => {
    buildTypewriter(e);
  });
});
