import Rails from "@rails/ujs";
import "channels";

import "./sentry.js";

Rails.start()

// Images
require('../images/coclick-logo-h40.png');
require('../images/coclick-logo-h40@2x.png');
require('../images/coclick-logo-short-white-h30.png');
require('../images/coclick-logo-short-white-h30@2x.png');
require('../images/home-illu.jpg');

require('./toasts');

window.jQuery = $;
window.$ = $;
window.Popper = Popper;
window.setupCcCookiesBanner = require('../src/cc-cookies-banner/').default;
