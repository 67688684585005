var bannerDom = require('./banner.html');
var modalDom = require('./modal.html');
var cookiesTableDom = require('./cookies-table.html');

import './style.scss';
import Cookies from 'js-cookie';
import { Modal } from 'bootstrap';
import ahoy from "ahoy.js";

class CcCookiesBannerBuilder {
  constructor(settings) {
    this.cookies_policy_url = settings.cookies_policy_url;
    this.cookies_setup = settings.cookies_setup;
  }

  setup() {
    var cookiesConsentRawValue = Cookies.get('coclick-consent-cookies');
    var cookiesConsentPresent = (cookiesConsentRawValue != undefined && cookiesConsentRawValue != null);
    if (!cookiesConsentPresent)
    {
      this.openBanner();
    }
  }

  openBanner() {
    this.bannerWrapper = $( bannerDom );
    this.bannerWrapper.find('a.cc-cookies-banner--read-more-anchor').attr('href', this.cookies_policy_url);
    this.bannerWrapper.find('button.cc-cookies-banner--customize-button').on('click', (e) => this.showModal());
    this.bannerWrapper.find('button.cc-cookies-banner--accept-all-button').on('click', (e) => this.acceptAll());
    this.bannerWrapper.find('button.cc-cookies-banner--reject-all-button').on('click', (e) => this.rejectAll());
    $( 'body' ).append(this.bannerWrapper);
  }

  showModal() {
    this.bannerWrapper.remove();
    this.bannerWrapper = undefined;
    this.modal = $( modalDom );
    this.cookies_setup.forEach((cookiesCategoryElement) => {
      var dom = $( cookiesTableDom );
      dom.attr('data-category', cookiesCategoryElement.category);
      dom.find('h4.cc-cookies-banner--cookies-table--title').html(cookiesCategoryElement.category_title);
      dom.find('p.cc-cookies-banner--cookies-table--description').html(cookiesCategoryElement.category_description);

      var domTitleTarget = dom.find('h4.cc-cookies-banner--cookies-table--title');
      if (cookiesCategoryElement.mandatory)
      {
        domTitleTarget.append($(`<small><span class="fw-bold text-success">Always active</span></small>`));
      }
      else
      {
        domTitleTarget.append($(`
              <div class="form-check form-switch">
                <input class="form-check-input" type="checkbox" role="switch" id="consentCategory${cookiesCategoryElement.category}" class="consentCategory" name="${cookiesCategoryElement.category}" checked>
              </div>
          `));
      }

      cookiesCategoryElement.cookies.forEach((e) => {
        dom.find('tbody.cc-cookies-banner--cookies-table--tbody').append($(`
        <tr>
          <td>${e.name}</td>
          <td>${e.description}</td>
          <td>${e.duration}</td>
        </tr>
        `));
      });
      this.modal.find('form.cc-cookies-banner--consent-form').append(dom);
    });
    this.modal.find('button.cc-cookies-banner--save').on('click', (e) => this.saveModalConsent());
    this.modal.find('button.cc-cookies-banner--accept-all-button').on('click', (e) => this.acceptAll());
    this.modal.find('button.cc-cookies-banner--reject-all-button').on('click', (e) => this.rejectAll());
    $( 'body' ).append( this.modal );

    this.bsModal = new Modal(this.modal, {
      backdrop: 'static',
      keyboard: false
    });
    this.bsModal.show();
  }

  saveModalConsent() {
    var formData = new FormData(this.modal.find('form.cc-cookies-banner--consent-form')[0]);
var object = {};
formData.forEach(function(value, key){
    object[key] = value;
});
    var consentKeys = [];
    formData.forEach((value, key) => {
      if (value == 'on')
      {
        consentKeys.push(key);
      }
    });
    var consentKey = consentKeys.join(',');
    this.setCookieConsentKey(consentKey);
  }

  acceptAll() {
    this.setCookieConsentKey('all');
  }

  rejectAll() {
    this.setCookieConsentKey('');
  }

  setCookieConsentKey(consentKey) {
    var isSecure = location.protocol === 'https:';
    Cookies.set('coclick-consent-cookies', consentKey, { path: '/', expires: 365, secure: isSecure });
    var consentedKeys = consentKey.split(',');
    var analyticsConsented = consentedKeys.some((e) => {
      return e == 'analytics' || e == 'all';
    });
    if (analyticsConsented)
    {
      ahoy.track("cookies_consented", { consentKey: consentKey });
    }

    if (this.bsModal != undefined)
    {
      this.bsModal.hide();
      this.modal[0].addEventListener('hidden.bs.modal', (e) => {
        this.bsModal.dispose();
        this.modal = undefined;
        this.bsModal = undefined;
      });
    }
    if (this.bannerWrapper != undefined)
    {
      this.bannerWrapper.remove();
      this.bannerWrapper = undefined;
    }
  }
}

var setupCcCookiesBanner = function ( options ) {
  var settings = $.extend({
    cookies_policy_url: '#',
    cookies_setup: []
  }, options );

  var builder = new CcCookiesBannerBuilder(settings);
  builder.setup();

  return builder;
};



export default setupCcCookiesBanner;
