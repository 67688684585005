import hljs from 'highlight.js';
import 'highlight.js/styles/stackoverflow-dark.css';

import Typewriter from 'typewriter-effect/dist/core';

import './style.scss'

class CcTerminalBuilder {
  constructor(target) {
    this.target = target;
    this.lineTargets = this.target.find('code').children();

    this.setup();
  }

  setup() {
    this.lineTargets.each((i, line) => {
      const originalDom = $(line).text();
      const language = $(line).data('language');
      const hljsNode = hljs.highlight(originalDom, { language: language } );
      $( line ).html(hljsNode.value);
    });
  }

  startAnimation() {
    this.nodes = []
    this.lineTargets.each((i, line) => {
      this.nodes.push(line);
      $( line ).remove();
    });

    // this.animate();
    this.animateWhenInView();
  }

  animateWhenInView() {
    var started = false;

    // This is then function used to detect if the element is scrolled into view
    function elementScrolled(elem)
    {
      var docViewTop = $(window).scrollTop();
      var docViewBottom = docViewTop + $(window).height();
      var elemTop = $(elem).offset().top;
      return ((elemTop <= docViewBottom));
    }
    $(window).scroll(() => {
      if (started) {
        return;
      }

      // This is where we use the function to detect if ".box2" is scrolled into view, and when it is add the class ".animated" to the <p> child element
      if (elementScrolled(this.target)) {
        started = true;

        this.animate();
      }
    });


    if (elementScrolled(this.target)) {
      started = true;
      this.animate();
    }

  }

  animate() {
    this.target.empty();
    var tw = new Typewriter(this.target.get(0), {
      delay: 25,
      cursor: '<span class="cc-terminal-cursor">&nbsp;</span>'
    });
    this.nodes.forEach((node) => {
      if ($(node).hasClass('command'))
      {
        tw.typeString(node.outerHTML).pauseFor(500);
      }
      else
      {
        tw.callFunction((twNode) => {
          twNode.elements.wrapper.append('\n');
          twNode.elements.wrapper.append(node);
          twNode.elements.wrapper.append('\n');
        }, node);
      }
    });

    var mThis = this;
    tw.callFunction((twNode) => {
      setTimeout(() => {
        tw.stop();
        this.animate();
      }, 5000);
    });

    tw.start();
  }
}

var buildCcTerminal = function () {
  var builder = new CcTerminalBuilder($(this));

  return builder;
};

$( document ).ready(() => {
  $( '.cc-terminal' ).each((i, e) => {
    var builder = new CcTerminalBuilder($( e ));
    builder.startAnimation();
  });
});

export default buildCcTerminal;
