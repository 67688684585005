const bootstrap = require('bootstrap');
require('bootstrap-icons/font/bootstrap-icons.css');
import '../src/common';

import '../stylesheets/home';

require('../src/youtube_loader');
require('../src/cc-typewriter');

var buildCcTerminal = require('../src/cc-terminal/');
window.$.fn.buildCcTerminal = buildCcTerminal.default;
